import { GameDetailType } from '@interfaces/Game';
import { Stage } from '@interfaces/Stage';
import logger from '@logger/logger';
import { getStagesReq } from '@services/stages';
import React, { createContext, useState } from 'react';

interface StagesContextInterface {
  stages: Stage[] | undefined;
  setStages({}: Stage[]): any;
  getQuizStage(): Stage | undefined;
  loadNewStages(): Promise<any>;
  loading: boolean;
}

interface StageInterfaceProps {
  children: React.Component | any;
  stages?: Stage[];
}

export const StagesContext = createContext<StagesContextInterface>(
  {} as StagesContextInterface
);

export default function StagesProvider({
  children,
  stages: initialStages,
}: StageInterfaceProps) {
  const [stages, setStages] = useState<Stage[] | undefined>(initialStages);
  const [loading, setIsLoading] = useState(false);

  const loadNewStages = async () => {
    logger.info('loadStates called');
    setIsLoading(true);
    try {
      const data = await getStagesReq();
      setStages(data as Stage[]);
      setIsLoading(false);
      return data as Stage[];
    } catch (e) {
      setIsLoading(false);
      return undefined;
    }
    // getStagesReq().then((data) => {
    //   console.log(data);
    // });
  };
  // console.log("stages",stages)

  const getQuizStage = () => {
    let stage;
    stage = stages?.map((v) => {
      let game = v.games.find((g) => g.type == GameDetailType.Quiz);
      return { ...v, games: [game] };
    }) as Stage[] | undefined;

    console.log("getQuizStage:",stage)
    if(stage != undefined){
      return stage[0];
    }
    return undefined;
  };
  return (
    <StagesContext.Provider
      value={{
        stages,
        setStages,
        loadNewStages,
        getQuizStage,
        loading,
      }}
    >
      {children}
    </StagesContext.Provider>
  );
}
